<template>
    <v-row justify="center">
        <v-col cols="12">
            <page-toolbar previous-page-label="MTurk Top" title="Qualifications">
                <template #contents>
                    <create-qualification-type-dialog-button
                        :client="client"
                        @complete="onComplete"
                    />
                    <delete-qualification-types-dialog-button
                        :client="client"
                        :qualification-type-ids="qualificationTypeIds"
                        :checked-qualification-type-ids="checkedQualificationTypeIds"
                        @complete="onComplete"
                    />
                </template>
            </page-toolbar>
        </v-col>

        <v-col cols="10">
            <v-data-table
                dense
                show-select
                sort-desc
                :headers="qualHeaders"
                :items="qualificationTypes"
                :single-expand="false"
                :expanded.sync="expanded"
                item-key="QualificationTypeId"
                class="elevation-1"
                :loading="loading"
                v-model="checkedQualificationTypes"
                sort-by="CreationTime">

                <!--<template v-slot:item.num_workers="{ item }">
                    <v-btn
                        text
                        :loading="item.num_workers==-2"
                        @click="item.num_workers=-2; listWorkersWithQualificationType(item.QualificationTypeId);">
                        <span v-if="item.num_workers>=0"> {{ item.num_workers }} </span>
                        <span v-else> ... </span>
                    </v-btn>
                </template>-->

                <template v-slot:top>
                    <v-card-title>

                        <v-spacer></v-spacer>

                        <v-text-field
                            single-line
                            hide-details 
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search" />
                    </v-card-title>
                </template>

                <template v-slot:item.QualificationTypeId="{ item }">
                    <div class="text-truncate" style="max-width:100px;">
                        {{ item.QualificationTypeId }}
                    </div>
                </template>

                <template v-slot:item.CreationTime="{ item }">
                    {{ standardDateTimeFormat(item.CreationTime) }}
                </template>

                <!--<template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <div class="my-2">
                            <div class="d-flex flex-row-reverse">
                                <v-btn icon color="grey lighten-1"><v-icon>mdi-pencil</v-icon></v-btn>
                                <v-btn icon color="grey lighten-1"><v-icon>mdi-account-edit</v-icon></v-btn>
                            </div>
                            Description: <b>{{ item.detail.Description }}</b><br>
                            Created at: <b>{{ item.detail.CreationTime }}</b><br>
                            Automatically grant on submission: <b>{{ item.detail.AutoGranted }}</b><br>
                            # of assigned workers: <b>{{ item.detail.workers ? item.detail.workers.length : 'retrieving...' }}</b><br>
                            Raw data:
                            <vue-json-pretty :data="item.detail" :deep="1" style="font-size:0.6em;"></vue-json-pretty>
                        </div>
                    </td>
                </template>-->

            </v-data-table>
        </v-col>

    </v-row>
</template>
<script>
import CreateQualificationTypeDialogButton from './CreateQualificationTypeDialogButton'
import DeleteQualificationTypesDialogButton from './DeleteQualificationTypesDialogButton'
import PageToolbar from '@/components/ui/PageToolbar'
import { onMTurkCredentialsUpdate } from '@/lib/mturk'
import { standardDateTimeFormat } from '@/lib/utils'

export default {
    components: {
        CreateQualificationTypeDialogButton,
        DeleteQualificationTypesDialogButton,
        PageToolbar,
    },
    data: () => ({

        search: "",
        expanded: [],
        qualHeaders: [
          { text: 'Name', value: 'Name' },
          { text: 'Status', value: 'QualificationTypeStatus' },
          { text: 'QualificationTypeId', value: 'QualificationTypeId' },
          //{ text: '# Workers', value: 'num_workers' },
          { text: 'CreationTime', value: 'CreationTime' },
          //{ text: '', value: 'data-table-expand' },
        ],
        loading: false,
        qualificationTypes: [],
        checkedQualificationTypes: [],
    }),
    props: ['client', 'credentials'],

    computed: {
        qualificationTypeIds() {
            return this.qualificationTypes.map((qt) => (qt.QualificationTypeId));
        },
        checkedQualificationTypeIds() {
            return this.checkedQualificationTypes.map((qt) => (qt.QualificationTypeId));
        },
    },
    methods: {
        standardDateTimeFormat,
        async listQualificationTypes() {
            this.loading = true;
            this.qualificationTypes = await this.client.mturk.listQualificationTypes();
            this.loading = false;
        },
        onComplete() {
            this.listQualificationTypes();
            this.checkedQualificationTypes = [];
        }
    },
    mounted() {
        onMTurkCredentialsUpdate(this, async () => {
            this.listQualificationTypes();
        });
    }
}
</script>
<style>
.v-data-table tbody tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f5f5f5;
}
.is-root, .is-root div {
    font-size: 9pt;
}
</style>
