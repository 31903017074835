<template>
    <div>
        <combobox-form-dialog-button
            ref="dialog"
            :items="qualificationTypeIds"
            :checked-items="checkedQualificationTypeIds"
            combobox-label="Qualification Type IDs"
            button-label="Delete Qualification Types"
            button-icon="mdi-trash-can"
            :progress="progress"
            @confirm="onConfirm"
            @complete="onComplete"
            @dialog-open="onDialogOpen"
        >
            <template #title>
                Delete Qualifications Types
            </template>
            <template #alert-body="{ selectedItems }">
                <v-card-text>
                    Delete {{ selectedItems.length }} Qualification Types?
                </v-card-text>
            </template>
        </combobox-form-dialog-button>
    </div>
</template>
<script>
import ComboboxFormDialogButton from '../ui/ComboboxFormDialogButton'

export default {
    components: {
        ComboboxFormDialogButton,
    },
    data: () => ({
        qualificationTypeItems: [],
        progress: {
            shown: false,
            percentage: 0,
            errors: [],
            counts: {}
        },
        inputs: {}
    }),
    props: ['client', 'qualificationTypeIds', 'checkedQualificationTypeIds'],
    methods: {
        onConfirm(qualificationTypeIds) {
            try {
                this.progress.shown = true;
                this.progress.counts = {
                        success: 0,
                        error: 0,
                        all: qualificationTypeIds.length,
                    },
                this.client.mturk.deleteQualificationTypes.send({
                    qualification_type_ids: qualificationTypeIds
                });
            } catch (e) {
                this.progress.shown = false;
                this.$root.$emit('tutti-snackbar', 'error', 'Failed in deleting qualification types');
                console.error(e);
            }
        },
        onComplete() {
            this.$emit('complete');
        },
        onDialogOpen() {
            this.progress.shown = false;
            this.progress.errors = [];
        }
    },
    mounted() {
        this.client.invokeOnOpen(() => {
            this.client.mturk.on('deleteQualificationTypes', {
                success: (data) => {
                        if(data) {
                            this.progress.counts = data.counts;
                            this.progress.percentage = (data.counts.success+data.counts.error)/data.counts.all*100;
                            this.progress.errors = data.errors;
                        }
                    },
                error: (data) => {
                        this.progress.shown = false;
                        this.$root.$emit('tutti-snackbar', 'error', 'Failed in deleting qualification types');
                        console.error(data);
                    }
            });
        });
    }
}
</script>
